<template>
  <div v-loading="loading" class="container">
    <div class="operate">
      <div class="title">已删考试</div>
      <el-input
        v-model="search.name"
        placeholder="请输入考试名称"
        style="width: 220px"
        @input="searchName"
      ></el-input>
    </div>
    <div class="tag">
      <i class="el-icon-warning"></i>删除后的考试项目将显示在此处，最多保留 30
      天，到期后将彻底删除不可恢复。
    </div>
    <el-table :data="tableData" stripe border style="width: 100%">
      <el-table-column prop="name" align="center" label="考试名称" width="180">
      </el-table-column>
      <el-table-column prop="examType" align="center" label="模式">
        <template slot-scope="{ row }"
          >{{ row.examType == 1 ? "新高考" : "普通考试" }}
        </template>
      </el-table-column>
      <el-table-column prop="level" align="center" label="学段">
        <template slot-scope="{ row }">
          <span v-if="row.level == 1">小学</span>
          <span v-if="row.level == 2">初中</span>
          <span v-if="row.level == 3">高中</span>
          <span v-if="row.level == 4">大学</span>
        </template>
      </el-table-column>
      <el-table-column prop="year" align="center" label="年级">
      </el-table-column>
      <el-table-column prop="type" align="center" label="类型">
        <template slot-scope="{ row }">
          {{ getExamType(row.type) }}
        </template>
      </el-table-column>
      <el-table-column prop="examName" align="center" label="风险等级">
        <template slot-scope="{ row }">
          <span v-if="row.securityLevel == 1">低风险</span>
          <span v-if="row.securityLevel == 2">高风险</span>
        </template>
      </el-table-column>
      <el-table-column prop="subjectNames" align="center" label="科目">
      </el-table-column>
      <el-table-column prop="examDate" align="center" label="考试日期">
      </el-table-column>
      <el-table-column prop="updateTime" align="center" label="删除日期">
      </el-table-column>
      <el-table-column prop="delUsername" align="center" label="操作用户">
      </el-table-column>
      <el-table-column prop="deadLine" align="center" label="彻底删除剩余">
        <template slot-scope="{ row }">
          <template v-if="row.deadLine !== null">
            <span v-if="row.deadLine > 7">{{ row.deadLine }}天</span>
            <span v-else style="color: #fe8516">{{ row.deadLine }}天</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="examName" align="center" width="140" label="操作">
        <template slot-scope="{ row }">
          <el-button type="text" @click="rollBack(row)"> 恢复</el-button>
          <el-button type="text" style="color: #f56c6b" @click="del(row)">
            彻底删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-current="search.current"
        :current-sizes="[10, 20, 30, 40]"
        :current-size="search.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      title="彻底删除考试项目"
      :visible.sync="dialogVisible"
      width="400px"
    >
      <div>
        即将彻底删除<span style="color: #f56c6b"
          >{{ examName }} 删除后将不可恢复，请身份验证后继续。</span
        >
      </div>
      <div class="password">
        <span>验证登录密码以继续</span>

        <el-input
          v-model="name"
          style="width: 1px; height: 1px; opacity: 0"
          placeholder="请输入内容"
        ></el-input>

        <a-input-password
          v-model="password"
          placeholder=""
          autocomplete="new-password"
          style="width: 206px"
          @keyup.enter.native="monitorBack"
        />
      </div>
      <div style="margin-top: 12px; text-align: center">此操作不可撤销</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="danger" :loading="btnLoading" @click="monitorBack">
          继续删除
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { examTypeOptions } from "@/core/util/constdata";
import { deletelist, reduction, deleteAgain } from "@/core/api/newOther/index";
import { encryption } from "@/core/util/util";
import { filterSubject } from "@/core/util/util";
export default {
  name: "Recycle",
  data() {
    return {
      tableData: [],
      examTypeOptions: examTypeOptions,
      total: 0,
      loading: false,
      search: {
        current: 1,
        size: 10,
        name: "",
      },
      subjectList: filterSubject(),
      dialogVisible: false,
      password: undefined,
      deleteId: null,
      name: "",
      examName: "",
      btnLoading: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    searchName() {
      this.search.current = 1;
      this.getData();
    },
    async getData() {
      try {
        this.loading = true;
        const res = await deletelist({ ...this.search });
        this.tableData = res.data.data.records.map((item) => {
          let result =
            (new Date().valueOf() - new Date(item.updateTime).valueOf()) /
            (1000 * 3600 * 24);
          let subjectIds = item.subjectIds.split(",").map(Number);
          let subjectNames = subjectIds
            .map((it) => {
              let subject = this.subjectList.find((i) => i.value === it);
              if (subject) {
                return subject.label;
              } else {
                return "";
              }
            })
            .filter((item) => item)
            .join(",");
          return {
            ...item,
            deadLine: 30 - parseInt(result),
            subjectNames: subjectNames,
          };
        });
        this.total = res.data.data.total;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    del(row) {
      this.dialogVisible = true;
      this.deleteId = row.id;
      this.examName = row.name;
    },
    async monitorBack() {
      if (!this.password) {
        this.$message({
          showClose: true,
          message: "请输入密码！",
          type: "warning",
        });
        return;
      }
      let userInfo = {
        randomStr: "blockPuzzle",
        code: "xxx",
        password: this.password,
      };
      const user = encryption({
        data: userInfo,
        key: "pigxpigxpigxpigx",
        param: ["password"],
      });
      let data = {
        id: this.deleteId,
        password: user.password,
      };
      try {
        this.btnLoading = true;
        await deleteAgain(data);
        this.getData();
        this.dialogVisible = false;
        this.$message({
          type: "success",
          message: "删除成功！",
          showClose: true,
        });
        this.btnLoading = false;
      } catch {
        this.btnLoading = false;
      }
    },
    rollBack(row) {
      const h = this.$createElement;
      this.$msgbox({
        message: h("p", null, [
          h("span", null, "恢复后,"),
          h("span", { style: "color: #2474ED" }, row.name),
          h("span", null, "将放回考试列表下！"),
        ]),
        title: "恢复考试项目",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await reduction({ id: row.id });
          this.$message({
            type: "success",
            message: "考试项目已恢复！",
            showClose: true,
          });
          this.getData();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handleSizeChange(val) {
      this.search.size = val;
    },
    handleCurrentChange(val) {
      this.search.current = val;
    },
    getExamType(type) {
      if (type) {
        const data = examTypeOptions.find((item) => item.value === type);
        return data.label;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  box-sizing: border-box;

  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;

    .title {
      font-size: 16px;
      color: #0a1119;
      font-weight: 600;
    }
  }

  .tag {
    box-sizing: border-box;
    padding: 18px 10px;
    line-height: 1;
    background: #fdf6ec;
    font-size: 14px;
    border-radius: 4px;
    margin-bottom: 18px;

    i {
      color: #fe8516;
      font-size: 15px;
      margin-right: 10px;
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 18px;
}
</style>
